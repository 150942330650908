import axios from 'axios'
export default {
  async GetPrivilegeCodeList(form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Privilege/list`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetPrivilegeCodeByID(id) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/Privilege/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async CreatePrivilegeCode(form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Privilege`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async UpdatePrivilegeCode(form) {
    try {
      var response = await axios.put(`${process.env.VUE_APP_API}/Privilege/${form.id}`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetPrivilegeCondition(form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Privilege/condition/${form.id}`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetUsedPrivilegeReport(filter) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Privilege/user_privilege_list`, filter)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async ExportPrivilegeReport(filter) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Privilege/export_customer_privilege_list`, filter, {
        responseType: 'blob'
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}
