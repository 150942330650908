import axios from "axios";
export const state = () => ({
  state: {
    reportCustomerList: {},
    respExportCustomer: {},
    respCustomerDetail: {},
    reportPurchaseHistory: {},
    reportPointHistoryList: {},
    branchListReport: {},
    respNote: {},
    updateNoteCustomer: {},
    updateProfileCustomer: {},
    stateGetPointByReportCustomer: null,
    stateUpdatePointByReportCustomer: null,
    stateReportTransactionList: null,
    stateExportReportTransactionList: null,
    stateReportTransactionById: null,
    stateUpdateNoteReportTransaction: null,
    stateNoteReportTransaction: null,
    stateTransactionBranchPoint: null,
    stateDeleteTransactionById: null,
    stateVoidTransactionById: null,
    statePointByBranch: null,
    stateCustomerField: null,
    stateUpdateCustomerField: null,
    stateCustomerCoupon: null,
  },
});

export const mutations = {
  setReportCustomerList: async function (state, val) {
    state.reportCustomerList = val;
  },
  setRespExportCustomer: async function (state, val) {
    state.respExportCustomer = val;
  },
  setRespCustomerDetail: async function (state, val) {
    state.respCustomerDetail = val;
  },
  setReportPurchaseHistory: async function (state, val) {
    state.reportPurchaseHistory = val;
  },
  setReportPointHistoryList: async function (state, val) {
    state.reportPointHistoryList = val;
  },
  setBranchListReport: async function (state, val) {
    state.branchListReport = val;
  },
  setNoteReportCustomer: async function (state, val) {
    state.respNote = val;
  },
  setUpdateNoteCustomer: async function (state, val) {
    state.updateNoteCustomer = val;
  },
  setUpdateProfileCustomer: async function (state, val) {
    state.updateProfileCustomer = val;
  },
  setGetPointByReportCustomer: async function (state, val) {
    state.stateGetPointByReportCustomer = val;
  },
  setUpdatePointByReportCustomer: async function (state, val) {
    state.stateUpdatePointByReportCustomer = val;
  },
  setReportTransactionList: async function (state, val) {
    state.stateReportTransactionList = val;
  },
  setExportReportTransactionList: async function (state, val) {
    state.stateExportReportTransactionList = val;
  },
  setReportTransactionById: async function (state, val) {
    state.stateReportTransactionById = val;
  },
  setUpdateNoteReportTransaction: async function (state, val) {
    state.stateUpdateNoteReportTransaction = val;
  },
  setNoteReportTransaction: async function (state, val) {
    state.stateNoteReportTransaction = val;
  },
  setTransactionBranchPoint: async function (state, val) {
    state.stateTransactionBranchPoint = val;
  },
  setDeleteTransactionById: async function (state, val) {
    state.stateDeleteTransactionById = val;
  },
  setVoidTransactionById: async function (state, val) {
    state.stateVoidTransactionById = val;
  },
  setPointByBranch: async function (state, val) {
    state.statePointByBranch = val;
  },
  setCustomerField: async function (state, val) {
    state.stateCustomerField = val;
  },
  setUpdateCustomerField: async function (state, val) {
    state.stateUpdateCustomerField = val;
  },
  setCustomerCoupon: async function (state, val) {
    state.stateCustomerCoupon = val;
  },
};

export const actions = {
  getReportCustomerList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/customer_list`, payload)
      .then(
        async (data) => {
          commit("setReportCustomerList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  exportReportCustomerList: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_REPORT_API}/customer/export_customer_list`,
        payload,
        {
          responseType: "blob",
        }
      )
      .then(
        (data) => {
          commit("setRespExportCustomer", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getReportCustomerDetail: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/customer/customer_detail/${payload}`)
      .then(
        async (data) => {
          commit("setRespCustomerDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getReportPurchaseHistory: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/transaction_history`, payload)
      .then(
        async (data) => {
          commit("setReportPurchaseHistory", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getReportPointHistoryList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/point_history`, payload)
      .then(
        async (data) => {
          commit("setReportPointHistoryList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getBranchFilterTransaction: async ({ commit }) => {
    await axios.get(`${process.env.VUE_APP_API}/Branch/list`).then(
      async (data) => {
        commit("setBranchListReport", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getNoteReportCustomer: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/customer/customer_note/${payload}`)
      .then(
        async (data) => {
          commit("setNoteReportCustomer", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateNoteReportCustomer: async ({ commit }, payload) => {
    await axios
      .put(`${process.env.VUE_APP_API}/customer/update_customer_note`, payload)
      .then(
        async (data) => {
          commit("setUpdateNoteCustomer", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateProfileCustomerReport: async ({ commit }, payload) => {
    await axios
      .put(
        `${process.env.VUE_APP_API}/customer/update_customer_detail`,
        payload
      )
      .then(
        async (data) => {
          commit("setUpdateProfileCustomer", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getPointByReportCustomer: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Customer/customer_point/${payload}`)
      .then(
        async (data) => {
          commit("setGetPointByReportCustomer", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updatePointByReportCustomer: async ({ commit }, payload) => {
    await axios
      .put(`${process.env.VUE_APP_API}/Customer/update_customer_point`, payload)
      .then(
        async (data) => {
          commit("setUpdatePointByReportCustomer", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getReportTransactionList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Transaction/transaction_list`, payload)
      .then(
        async (data) => {
          commit("setReportTransactionList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  exportReportTransactionList: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_REPORT_API}/Transaction/export_transaction_list_file`,
        payload
      )
      .then(
        (data) => {
          commit("setExportReportTransactionList", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getReportTransactionById: async ({ commit }, payload) => {
    await axios
      .get(
        `${process.env.VUE_APP_API}/Transaction/transaction_detail/${payload}`
      )
      .then(
        async (data) => {
          commit("setReportTransactionById", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateNoteReportTransaction: async ({ commit }, payload) => {
    await axios
      .put(
        `${process.env.VUE_APP_API}/Transaction/update_transaction_note`,
        payload
      )
      .then(
        async (data) => {
          commit("setUpdateNoteReportTransaction", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getNoteReportTransaction: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Transaction/transaction_note/${payload}`)
      .then(
        async (data) => {
          commit("setNoteReportTransaction", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getTransactionBranchPoint: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Transaction/branch_point/${payload}`)
      .then(
        async (data) => {
          commit("setTransactionBranchPoint", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  deleteTransactionById: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/Transaction/delete_transaction`,
        payload
      )
      .then(
        async (data) => {
          commit("setDeleteTransactionById", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  voidTransactionById: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Transaction/void_transaction`, payload)
      .then(
        async (data) => {
          commit("setVoidTransactionById", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getPointByBranch: async ({ commit }, payload) => {
    await axios
      .get(
        `${process.env.VUE_APP_API}/customer/customer_point/${payload.user_guid}/${payload.branch_id}`
      )
      .then(
        async (data) => {
          commit("setPointByBranch", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCustomerField: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Customer/custom_field/${payload}`)
      .then(
        async (data) => {
          commit("setCustomerField", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateCustomerField: async ({ commit }, payload) => {
    await axios
      .put(`${process.env.VUE_APP_API}/Customer/update_custom_answer`, payload)
      .then(
        async (data) => {
          commit("setUpdateCustomerField", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCustomerCoupon: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/customer/coupon`, payload)
      .then(
        async (data) => {
          commit("setCustomerCoupon", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
