import axios from "axios";
export const state = () => ({
    state: {
        listSetting: {},
        listTerms: {},
        stateUpdatePrivacyPolicy: {},
        stateUpdateTermsCondition: {},
        stateDetailPolicy: {},
        stateDetailTerms: {},
        stateLogout: {},
        stateLogoutAzure: {}
    }
})

export const mutations = {
    setListSetting: async function (state, val) {
        state.listSetting = val;
    },
    setListTerm: async function (state, val) {
        state.listTerms = val;
    },
    setUpdatePrivacyPolicy: async function (state, val) {
        state.stateUpdatePrivacyPolicy = val;
    },
    setUpdateTermsCondition: async function (state, val) {
        state.stateUpdateTermsCondition = val;
    },
    setDetailPolicy: async function (state, val) {
        state.stateDetailPolicy = val;
    },
    setDetailTerms: async function (state, val) {
        state.stateDetailTerms = val;
    },
    setLogout: async function (state, val) {
        state.stateLogout = val;
    },
    setLogoutAzure: async function (state, val) {
        state.stateLogoutAzure = val;
    },
}

export const actions = {
    getListSetting: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/DSR/privacy_policy_list`)
            .then(async data => {
                commit('setListSetting', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    getListTerm: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/DSR/terms_condition_list`)
            .then(async data => {
                commit('setListTerm', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    UpdatePrivacyPolicy: async ({ commit }, payload) => {
        await axios.put(`${process.env.VUE_APP_API}/DSR/privacy_policy`, payload)
            .then(async data => {
                commit('setUpdatePrivacyPolicy', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    UpdateTerms: async ({ commit }, payload) => {
        await axios.put(`${process.env.VUE_APP_API}/DSR/terms_condition
        `, payload)
            .then(async data => {
                commit('setUpdateTermsCondition', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    getDetailPolicy: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/DSR/privacy_policy`)
            .then(async data => {
                commit('setDetailPolicy', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    getDetailTerms: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/DSR/terms_condition`)
            .then(async data => {
                commit('setDetailTerms', data.data);

            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    getLogout: async ({ commit }) => {
        await axios.post(`${process.env.VUE_APP_API}/Login/logout`)
            .then(async data => {
                commit('setLogout', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) { }
            }
            );
    },
    getLogoutAzure: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/Login/UnPairAzure`)
            .then(async data => {
                commit('setLogoutAzure', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) { }
            }
            );
    },
}

export default {
    state,
    actions,
    mutations
}