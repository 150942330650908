<template>
  <div class="p-3 pre-box title-panel">
    <b-row>
      <b-col class="border-right" v-for="col of column" :key="col.name">
        <label class="f-18">{{ col.name }}</label>

        <div v-if="!isLoading">
          <span class="text-score" v-if="col.isDecimal">
            {{ data[col.key] | numeral("0,0.00") }}
            <span v-if="col.isPercent">%</span>
          </span>
          <span class="text-score" v-else>
            {{ data[col.key] | numeral("0,0") }}
            <span v-if="col.isPercent">%</span>
          </span>
        </div>
        <div v-else>
          <span class="text-score">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style>
.text-score {
  color: var(--primary-color);
  font-size: 42px;
}
</style>
