import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
    state: {
        modalAlertLogin : false,
        respImport : {},
        excelFile : {},
        customerList : {},
        transactionList : {},
        downloadCustomer : {},
        downloadTransaction :{},
        customerConfigFile : {},
        respImportConfig : {},
        transactionConfigFile : {},
        respImportTransaction : {},
        responseFileError : {},
        respImportTransaction : {}
    }
})

export const mutations = {
    setRespImport: async function(state,val){
        state.respImport = val;
    },
    setImportFile: async function(state,val){
        state.excelFile = val;
    },
    setCustomerList: async function(state,val){
        state.customerList = val;
    },
    setModalAlertLogin: async function(state,val){
        state.modalAlertLogin = val;
    },
    setTransactionList: async function(state,val){
        state.transactionList = val;
    },
    setDownloadCustomer: async function(state,val){
        state.downloadCustomer = val;
    },
    setDownloadTransaction: async function(state,val){
        state.downloadTransaction = val;
    },
    setCustomerConfigFile: async function(state,val){
        state.customerConfigFile = val;
    },
    setRespImportConfig: async function(state,val){
        state.respImportConfig = val;
    },
    setTransactionConfigFile: async function(state,val){
        state.transactionConfigFile = val;
    },
    setResponseFileError: async function(state,val){
        state.responseFileError = val;
    },
    setRespImportTransaction: async function(state,val){
        state.respImportTransaction = val;
    }
}

export const actions = {
    fileCustomer: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Import/importexcelcustomer`, payload)
        .then(async data => {
            commit('setRespImport',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
    getCustomerList: async ({ commit } , payload) => {
        await axios.post(`${process.env.VUE_APP_API}/Import/getcustomerlist`, payload)
        .then(async data => {
          commit('setCustomerList',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    getTransactionList: async ({ commit } , payload) => {
        await axios.post(`${process.env.VUE_APP_API}/Import/gettransactionlist`, payload)
        .then(async data => {
          commit('setTransactionList',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    downloadTemplateCustomer: async ({ commit }) => {
        await axios({
            url : `${process.env.VUE_APP_API}/Import/getcustomertemplate`,
            method : "get",
            responseType: "blob",
            // headers: {
            //     'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
            //     'API-KEY': process.env.VUE_APP_API_KEY
            // }
        })
        .then(async data => {
          commit('setDownloadCustomer',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    downloadTemplateTransaction: async ({ commit }) => {
        await axios({
            url : `${process.env.VUE_APP_API}/Import/gettransactiontemplate`,
            method : "get",
            responseType: "blob",
            // headers: {
            //     'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
            //     'API-KEY': process.env.VUE_APP_API_KEY
            // }
        })
        .then(async data => {
          commit('setDownloadTransaction',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    getCustomerConfigFile: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/Import/getimportcustomer`)
        .then(async data => {
          commit('setCustomerConfigFile',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    importConfig: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Import`, payload)
        .then(async data => {
          commit('setRespImportConfig',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    getTransactionConfigFile: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/Import/getimporttransaction`)
        .then(async data => {
          commit('setTransactionConfigFile',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    commit('setModalAlertLogin',true);
                }
            }
        );
    },
    importFileError: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Import/addrequirecolumn`, payload)
        .then(async data => {
            commit('setResponseFileError',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
    fileTransaction: async ({ commit } , payload ) => {
        await axios.post(`${process.env.VUE_APP_API}/Import/importexceltransaction`, payload)
        .then(async data => {
            commit('setRespImportTransaction',data.data);
        },(error) => { 
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
                commit('setModalAlertLogin',true);
            }
        });
    },
}

export default {
    state,
    actions,
    mutations
}