<template>
  <div>
    <label v-if="textFloat" class="main-label"> {{ textFloat }}</label>
    <!-- {{ options }} -->
    <multiselect
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      multiple
      :value="valueDisplay"
      @input="handleSelect"
      :options="options"
      :placeholder="placeholder"
      :track-by="valueField"
      :label="textField"
      :loading="isLoading"
      @remove="(val) => $emit('remove', val)"
    >
      <template #noResult> <div>No value found.</div> </template>
      <template #noOptions> <div>No value found.</div> </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: {
    options: { required: true, default: () => [] },
    placeholder: { required: false, default: "select or search value." },
    textField: { required: true },
    valueField: { required: true },
    value: { required: true },
    textFloat: { required: false },
    closeOnSelect: {
      required: false,
      default: false,
    },
    clearOnSelect: {
      required: false,
      default: false,
    },
    defaultValue: {
      required: false,
      default: false,
    },
    used: { required: false, default: false },
    isLoading: { required: false, default: false },
  },

  computed: {
    valueDisplay() {
      try {
        if (!this.used) {
          if (this.defaultValue) {
            return this.value;
          }

          return this.options.filter((el) =>
            this.value.includes(el[this.valueField])
          );
        }

        if (this.defaultValue) {
          return this.$store.state.filter[this.used];
        }

        return this.options.filter((el) =>
          this.$store.state.filter[this.used].includes(el[this.valueField])
        );
      } catch (error) {
        return;
      }
    },
  },

  methods: {
    handleSelect(value) {
      let val = this.defaultValue
        ? value
        : value.map((el) => el[this.valueField]);

      if (this.used) {
        this.$store.dispatch("setFilter", { key: this.used, value: val });
      }

      this.$emit("input", val);
    },
  },
};
</script>

<style></style>
