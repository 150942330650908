<template>
  <div>
    <div v-if="isLoadingImage" class="text-center">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div v-else>
      <UploadFile
        :textFloat="label"
        :placeholder="label"
        accept="image/png, image/jpeg"
        @onFileChange="handleFileChange"
        name="image"
        :fileName="fileName"
        :id="id"
        :isRequired="required"
      />
      <div v-if="isValidate" class="text-error ft-14">กรุณาเลือกรูปภาพ</div>
      <div class="panel-upload" v-if="images">
        <div class="panel-bg-file-img">
          <span
            class="icon-delete"
            color="#FFFFFF"
            @click.prevent="deleteImage"
            v-if="images"
            >x</span
          >
          <!-- <font-awesome-icon
            icon="plus"
            color="#40a9d1"
            class="icon-add"
            v-if="!images"
          /> -->
          <input
            type="file"
            v-on:change="onFileChange"
            :name="name"
            ref="input"
            v-if="!images"
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    required: {
      required: false,
      type: Boolean,
    },
    id: {
      required: true,
    },
    name: {
      required: false,
      type: String,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    img: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      images: this.img,
      fileName: "",
      value: "",
      isLoadingImage: false,
      showPreview: "",
    };
  },
  methods: {
    handleFileChange(img) {
      this.fileName = img.name;
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;

        this.$emit("onChangeImage", this.images);
      };
    },
    deleteImage() {
      this.images = "";
      this.fileName = "";
      this.showPreview = "";
      this.$emit("deleteImage", {
        type: this.fieldtype,
        questionId: this.questionId,
      });
    },
    saveImagetoDb: async function (img) {
      this.loadingImage = false;
      const form = {
        Base64String: img,
      };
      return await this.$axios
        .post(`${process.env.BASE_URL_IMG}/RegisterForm/UploadImage`, form)
        .then(async (data) => {
          this.isLoading = false;
          if (data.result == 1) {
            return data.detail;
          }
        });
    },
  },
};
</script>

<style scoped>
.panel-upload {
  position: relative;
  margin-bottom: 15px;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #707070;
}
.panel-bg-file-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  /* padding-bottom: 20%; */
  height: 100px;
  border: 2px dashed;
  border-radius: 6px;
  width: 100px;
  cursor: pointer;
}
input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  opacity: 0;
}
input[type="file"] {
  display: none;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
}
input.custom-input {
  background-color: white;
  border: 1px solid #bcbcbc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 7px 10px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 15px;
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}

.ft-14 {
  font-size: 14px;
}
.loading {
  width: 50px;
  margin: 10px 0;
}
</style>
