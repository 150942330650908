// import axios from "axios";
import { $report } from "@/services/axios";
export const state = {
  dateRange: null,
  syncFilterValue: null,
  filter: {
    customData: null,
    dateType: 1,
    form: null,
    to: null,
    product: [],
    branch: [],
    channel: [],
    age: [],
    gender: [],
    tier: [],
  },
};

export const mutations = {
  setRange: async function (state, val) {
    state.dateRange = val;
  },
  setFilterValue: async function (state, { key, value }) {
    state.filter[key] = value;
  },
  setDefaultFilter: function (state) {
    state.filter = {
      customData: null,
      dateType: 1,
      form: null,
      to: null,
      product: [],
      branch: [],
      channel: [],
      age: [],
      gender: [],
      tier: [],
    };
  },
};

export const actions = {
  getFilterDate: async ({ commit, state }, id) => {
    try {
      if (state.dateRange) return state.dateRange;
      const result = await $report(`/dashboard/Filter/max_min_date/${id}`);
      commit("setRange", result.data.detail);

      return result.data.detail;
    } catch (error) {
      console.error(error.message, "Filter Date Error");
    }
  },
  setFilter: async function ({ commit, state }, { key, value }) {
    commit("setFilterValue", { key, value });
  },
  clearFilter: async function ({ commit, state }) {
    commit("setDefaultFilter");
  },
};
export const getters = {
  filter: (state) => {
    return state.filter;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
