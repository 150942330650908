<template>
  <div class="position-relative mb-4">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <label v-if="textFloat">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div id="gjs" ref="editor"></div>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
    </div>
  </div>
</template>

<script>
import grapesjs from "grapesjs";
import "grapescss";
import "grapesjs";
import newsletter from "grapesjs-preset-newsletter";
// import ckeditor from "grapesjs-plugin-ckeditor";
import webpage from "grapesjs-preset-webpage";

import blocksBasic from "grapesjs-blocks-basic";
import blocksForms from "grapesjs-plugin-forms";

export default {
  name: "grapes-text-editor",
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: false,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      file: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
    };
  },
  mounted() {
    // let BlockAddVariables = (editor) => {
    //   for (const items of this.variableItem) {
    //     editor.BlockManager.add(`variable-${items.name}`, {
    //       label: items.name,
    //       category: "Variables",
    //       content: {
    //         type: "text",
    //         content: `${items.name}`,
    //         activeOnRender: 1,
    //       },
    //     });
    //   }
    // };

    this.editor = grapesjs.init({
      container: "#gjs",
      fromElement: true,
      storageManager: { type: 0 },
      assetManager: {
        uploadFile: async (ev) => {
          uploadFile(ev);
        },
      },
      colorPicker: {
        appendTo: "parent",
        offset: { top: 26, left: -166 },
      },
      plugins: [
        newsletter,
        // webpage,
        // ckeditor,
        // BlockAddVariables,
        blocksBasic,
        blocksForms,
      ],
      pluginsOpts: {
        // [ckeditor]: {
        //   options: {
        //     startupFocus: true,
        //     extraAllowedContent: "*(*);*{*}", // Allows any class and any inline style
        //     allowedContent: true, // Disable auto-formatting, class removing, etc.
        //     enterMode: 2, // CKEDITOR.ENTER_BR,
        //     extraPlugins: "sharedspace,justify,colorbutton,panelbutton,font",
        //     toolbar: [
        //       { name: "styles", items: ["Font", "FontSize"] },
        //       ["Bold", "Italic", "Underline", "Strike"],
        //       { name: "paragraph", items: ["NumberedList", "BulletedList"] },
        //       { name: "links", items: ["Link", "Unlink"] },
        //       { name: "colors", items: ["TextColor", "BGColor"] },
        //       { name: "document", items: ["Mode"] },
        //     ],
        //   },
        // },
      },
    });
    this.editor
      .on("load", async () => {
        await this.editor.setComponents(this.value);
      })
      .on("update", async () => {})
      .on("component:selected", async () => {
        let tag = await getSelectedTag();

        const commandToAdd = `open-modal-tag`;
        const commandIcon = "fa fa-tag";
        const selectedComponent = this.editor.getSelected();
        const defaultToolbar = selectedComponent.get("toolbar");

        // check if this command already exists on this component toolbar
        const commandExists = defaultToolbar.some(
          (item) => item.command === commandToAdd
        );

        // if it doesn't already exist, add it
        if (!commandExists) {
          selectedComponent.set({
            toolbar: [
              ...defaultToolbar,
              {
                attributes: {
                  class: commandIcon,
                  title: tag,
                  "data-tooltip-pos": "bottom",
                },
                command: commandToAdd,
              },
            ],
          });
        }
      });

    var pnm = this.editor.Panels;
    var cmdm = this.editor.Commands;
    var modal = this.editor.Modal;
    var am = this.editor.AssetManager;
    var cmp = this.editor.Components;
    // var sm = this.editor.Selectors;

    // upload image
    const addImage = (src) => {
      am.add({
        src: src,
        // type: 'image',	// image is default
        // 	height: 300,
        // width: 200,
      });
    };

    const uploadFile = async (ev) => {
      const files = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;
      await [...files].forEach(async (file) => {
        if (file.size > 50000000) {
          this.errorAlert("This file size is too large");
          return;
        }

        if (file) {
          var reader = new FileReader();
          await reader.readAsDataURL(file);
          reader.onload = async () => {
            let im = new Image();
            im.src = reader.result;
            im.onload = async () => {
              // if (im.width !== im.height) {
              //   this.$swal("Please upload image with ratio 1:1 !", {
              //     icon: "info",
              //   });
              // } else {
              var savetoDB = await this.saveImagetoDb(reader.result);
              let result = savetoDB;
              addImage(result);
              // }
            };
          };
        }
      });
    };

    // variables
    pnm.addButton("options", [
      {
        id: `view-modal-variables`,
        label: `Variables`,
        command: `open-modal-variables`,
        attributes: {
          title: `Variables`,
          "data-tooltip-pos": "bottom",
        },
      },
    ]);

    cmdm.add(`open-modal-variables`, async () => {
      await openModalVariables();
    });

    const openModalVariables = () => {
      const pfx = this.editor.getConfig().stylePrefix;
      const container = document.createElement("div");
      container.className = "mt-2";
      modal.setTitle("Variables");

      for (const item of this.variableItem) {
        const btnEdit = document.createElement("button");
        btnEdit.type = "button";
        btnEdit.innerHTML = item.name;
        btnEdit.className =
          pfx + "btn-prim " + pfx + "btn-import" + " btn-variable mr-2 mb-2";
        btnEdit.onclick = () => {
          addVariables(item.name);

          modal.close();
        };
        container.appendChild(btnEdit);
      }

      modal.setContent(container);
      modal.open();
    };

    const addVariables = (name) => {
      let selected = this.editor.getSelected();
      const { length } = selected.components();
      this.formMain.is_not_variable = 0;
      selected.append(`<span>{{${name}}}</span>`, { at: parseInt(length) });
    };

    // preview with variables
    pnm.addButton("options", [
      {
        id: `view-with-variables`,
        label: `<div class='fa fa-caret-square-o-right'></div>`,
        command: `open-with-variables`,
        attributes: {
          title: `Preview with Variables`,
          "data-tooltip-pos": "bottom",
        },
      },
    ]);

    cmdm.add(`open-with-variables`, async () => {
      await openModalWithVariables();
    });

    const openModalWithVariables = () => {
      const pfx = this.editor.getConfig().stylePrefix;
      const container = document.createElement("div");
      container.className = "mt-2";
      modal.setTitle("Preview with Variables");
      modal.setContent(container);

      const labelVariables = document.createElement("label");
      labelVariables.innerHTML = `Variables(${this.variableItem.length})`;
      labelVariables.className = "label-preview-variables font-weight-bold";
      container.appendChild(labelVariables);

      let inputVariableList = [];
      for (const item of this.variableItem) {
        const wrap = document.createElement("div");
        const label = document.createElement("label");
        label.innerHTML = item.name;
        label.className = "label-preview-variables";

        const inputVariable = document.createElement("input");
        inputVariable.type = "text";
        inputVariable.placeholder = item.name;
        inputVariable.name = `variables_${item.name}`;
        inputVariable[`data-name`] = item.name;
        inputVariable.className = "input-preview-variables";
        inputVariableList.push(inputVariable);

        wrap.appendChild(label);
        wrap.appendChild(inputVariable);
        container.appendChild(wrap);
      }

      const wrapTags = document.createElement("div");
      wrapTags.className = "text-center";
      let inputTagList = [];
      let wrapperCmp = this.editor.Pages.getSelected().getMainComponent();
      let inputTag = wrapperCmp.find(".data_tags");

      if (inputTag.length) {
        inputTag = inputTag[0];
        let value = inputTag.get("attributes").value;
        value = value.split(",");

        const labelTags = document.createElement("label");
        labelTags.innerHTML = `Tags(${value.length})`;
        labelTags.className = "label-preview-tags font-weight-bold";
        container.appendChild(labelTags);

        for (const tag of value) {
          const inputTag = document.createElement("input");
          inputTag.type = "checkbox";
          inputTag.checked = true;
          inputTag.value = tag;
          inputTag[`data-name`] = tag;
          inputTag.name = `tags_${tag}`;
          inputTag.class = `checkbox_tags`;

          inputTagList.push(inputTag);
          wrapTags.appendChild(inputTag);

          const label = document.createElement("label");
          label.innerHTML = tag;
          label.className = "label-tag";
          wrapTags.appendChild(label);
        }
      }

      container.appendChild(wrapTags);

      const wrap = document.createElement("div");
      wrap.className = "my-2 text-center";
      const btnSave = document.createElement("button");
      btnSave.type = "button";
      btnSave.innerHTML = "Preview";
      btnSave.className = pfx + "btn-prim " + pfx + "btn-import " + " px-3";
      btnSave.onclick = () => {
        addVariablesValue(inputVariableList, inputTagList);

        // modal.close();
      };

      wrap.appendChild(btnSave);
      container.appendChild(wrap);
      modal.open();
    };

    const addVariablesValue = async (variables, tags) => {
      let html = this.editor.runCommand("gjs-get-inlined-html");

      let wrapperCmp = this.editor.Pages.getSelected().getMainComponent();
      let inputTag = wrapperCmp.find('[have-tag="true"]');
      // let value = []
      // เช็คด้วยว่าห้ามซ้ำ

      if (inputTag.length) {
        let checkedList = [];
        for (const input of tags) {
          // let dataName = input[`data-name`];
          let value = input.value;
          let checked = input.checked;
          if (checked) {
            checkedList.push(value);
          }
        }

        for (const input of inputTag) {
          let tag = input.get("attributes")["data-tag"];
          if (!checkedList.includes(tag)) {
            input.setStyle({ display: "none" });
          }
        }
      }

      let htmlView = this.editor.runCommand("gjs-get-inlined-html");
      for (const input of variables) {
        let dataName = input[`data-name`];
        let value = input.value;
        if (value) {
          htmlView = htmlView.replaceAll(`{{${dataName}}}`, value);
        }
      }

      await this.editor.setComponents(html);

      var myWindow = window.open("", "preview", "_blank", "");
      myWindow.document.write(htmlView);
    };

    // tag
    cmdm.add(`open-modal-tag`, async () => {
      await openModalTag();
    });

    const getSelectedTag = () => {
      let selected = this.editor.getSelected();
      let tag = selected.get("attributes")["data-tag"] || "";

      return tag;
    };

    const openModalTag = async () => {
      let tag = await getSelectedTag();

      const pfx = this.editor.getConfig().stylePrefix;
      const container = document.createElement("div");
      container.className = "mt-2";
      modal.setTitle("Tag");

      const inputTag = document.createElement("input");
      inputTag.type = "text";
      inputTag.value = tag;
      inputTag.className = "input-tag";

      const btnSave = document.createElement("button");
      btnSave.type = "button";
      btnSave.innerHTML = "OK";
      btnSave.className =
        pfx + "btn-prim " + pfx + "btn-import " + " ml-3 px-3";
      btnSave.onclick = () => {
        addTag(inputTag.value.trim());

        modal.close();
      };

      container.appendChild(inputTag);
      container.appendChild(btnSave);

      modal.setContent(container);
      modal.open();
    };

    const addTag = async (name) => {
      let haveTag = "false";
      if (name) {
        let wrapperCmp = this.editor.Pages.getSelected().getMainComponent();
        let inputTag = wrapperCmp.find(".data_tags");
        let value = [];

        if (inputTag.length) {
          inputTag = inputTag[0];
          value = inputTag.get("attributes").value;
          value = value.split(",");
        } else {
          inputTag = cmp.addComponent({
            tagName: "input",
          });
          await inputTag.set("attributes", {
            name: "data_tags",
            class: "data_tags",
          });
        }

        if (!value.includes(name)) {
          value.push(name);
        }
        value = value.toString();
        await inputTag.set("attributes", { value: value, type: "hidden" });
        haveTag = "true"
      }
      
      let selected = this.editor.getSelected();
      await selected.set("attributes", {
        "data-tag": name,
        "have-tag": haveTag,
      });

      let html = this.editor.runCommand("gjs-get-inlined-html");
      await this.editor.setComponents(html);
    };

    // for (const items of this.variableItem) {
    //   cmdm.add(`open-variable-${items.name}`, () => {
    //     let selected = this.editor.getSelected();
    //     const { length } = selected.components();
    //     selected.append(`<span>{{${items.name}}}</span>`,
    //       { at: parseInt(length) }
    //    );
    //   });

    //   pnm.addButton("options", [
    //     {
    //       id: `view-variable-${items.name}`,
    //       label: `${items.name}`,
    //       command: `open-variable-${items.name}`,
    //       attributes: {
    //         title: items.name,
    //         "data-tooltip-pos": "bottom",
    //       },
    //     },
    //   ]);
    // }
  },
  methods: {
    saveImagetoDb: async function (img) {
      let form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      let data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        this.errorAlert(data.message);
      }
    },
    getHtml: async function () {
      var html = this.editor.runCommand("gjs-get-inlined-html");
      this.$emit("onDataChange", html);
    },
  },
};
</script>

<style lang="scss" scoped>
#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}
::v-deep .gjs-btn-tag.active {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
::v-deep .input-tag {
  height: 2rem;
  width: 50%;
  margin-bottom: 0.5rem !important;
}
::v-deep .label-preview-variables,
::v-deep .label-preview-tags {
  height: 2rem;
  width: 25%;
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  text-align: right;
}
::v-deep .label-tag {
  margin-left: 5px !important;
  margin-right: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}
::v-deep .input-preview-variables {
  height: 2rem;
  width: 50%;
  margin-bottom: 0.5rem !important;
}
.text-editor-custom {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.text-editor-custom.error #gjs {
  border: 2px solid red !important;
}
.text-error {
  color: red;
}
::v-deep .tox-notifications-container {
  display: none !important;
}
.btn-outline {
  background-color: #dbdbdb;
  border-radius: 20px;
  color: #000;
}
.title-msg {
  color: #000;
  font-size: 17px;
}
</style>
