import axios from "axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import swal2 from "sweetalert2";

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = Vue.$cookies.isKey("back_office_admin_token")
    ? config.url.includes("lazada")
      ? ""
      : `Bearer ${Vue.$cookies.get("back_office_admin_token")}`
    : "";
  config.headers.common["API-KEY"] = config.url.includes("lazada")
    ? ""
    : config.url.includes("-report-")
    ? process.env.VUE_APP_REPORT_KEY
    : process.env.VUE_APP_API_KEY;
  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  console.log(error);
  if (error.response.data.message == "Permission denied") {
    window.location.href = "/403";
  }
  if (error.response.status == 400) {
    swal2.fire("Internal Server Error", "Please try again Leter", "error");
  }
  if (error.response.status == 401) {
    swal2
      .fire("No access to information", "Please try again", "error")
      .then(() => {
        let cookiesDomain = window.location.hostname;
        VueCookies.remove("back_office_admin_token", null);
        VueCookies.remove("permission_menu_admin", null);
        VueCookies.remove("back_office_admin_permission", null);
        VueCookies.remove("back_office_admin_user_guid", null);
        VueCookies.remove("back_office_admin_email", null);
        VueCookies.remove("back_office_admin_userName", null);
        window.location.href = "/";
      });
  }
  if (error.response.status === 500) {
    swal2
      .fire("Internal Server Error.", "Please try again", "error")
      .then(() => {
        let cookiesDomain = window.location.hostname;
        VueCookies.remove("back_office_admin_token", null);
        VueCookies.remove("permission_menu_admin", null);
        VueCookies.remove("back_office_admin_permission", null);
        VueCookies.remove("back_office_admin_user_guid", null);
        VueCookies.remove("back_office_admin_email", null);
        VueCookies.remove("back_office_admin_userName", null);
        window.location.href = "/";
      });
  }
  return error;
});
const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  responseType: "json",
  timeout: 20000,
});
const $report = axios.create({
  baseURL: process.env.VUE_APP_REPORT_API,
  responseType: "json",
  timeout: 20000,
});
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = Vue.$cookies.isKey("back_office_admin_token")
    ? config.url.includes("lazada")
      ? ""
      : `Bearer ${Vue.$cookies.get("back_office_admin_token")}`
    : "";
  config.headers.common["API-KEY"] = config.url.includes("lazada")
    ? ""
    : config.url.includes("-report-")
    ? process.env.VUE_APP_REPORT_KEY
    : process.env.VUE_APP_API_KEY;
  return config;
});
$report.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${Vue.$cookies.get(
    "back_office_admin_token"
  )}`;

  config.headers.common["API-KEY"] = process.env.VUE_APP_REPORT_KEY;

  return config;
});

Vue.prototype.$Axios = axios;
Vue.prototype.axios = instance;
Vue.prototype.$report = $report;
export { instance, $report };
