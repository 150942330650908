<template>
  <div>
    <div class="position-relative">
      <label
        >{{ label }} <span v-if="isRequired" class="text-danger">*</span></label
      >
      <input
        class="input-autocomplete form-control"
        autocomplete="off"
        role="combobox"
        list=""
        :id="id"
        :name="dataListID"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput()"
        :ref="`input-${id}`"
      />

      <font-awesome-icon
        icon="chevron-down"
        class="arrow"
        @click="toggleClick()"
      />
      <datalist :id="dataListID" role="listbox">
        <option
          role="listoptions"
          v-for="(opt, index) of optionsList"
          :key="dataListID + '-' + opt.id + index"
          :value="opt[valueField || 'id']"
        >
          {{ opt.name }}
        </option>
      </datalist>
    </div>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false"
        >Please input text.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      required: false,
      default: false,
    },
    v: {
      required: false,
    },
    id: {
      required: true,
    },
    label: {
      required: false,
    },
    placeholder: {
      required: false,
      default: "Please select value",
    },
    showValue: { required: false },
    valueField: { required: false },
    dataListID: { required: true },

    optionsList: { required: true, default: [] },
    isRequired: { required: false },
  },
  data() {
    return {
      // countries: this.arrList,
      currentFocus: -1,
    };
  },

  mounted() {
    // this.autocomplete(document.getElementById(this.id), this.countries);
    let input = document.getElementById(this.id);
    let datalist = document.getElementById(this.dataListID);
    this.createEventInput(input, datalist);
    input.value = this.showValue;
  },
  watch: {
    optionsList(val) {
      if (val.length > 0) {
        this.$nextTick(() =>
          this.createEventInput(
            document.getElementById(this.id),
            document.getElementById(this.dataListID)
          )
        );
      }
    },
  },
  methods: {
    toggleClick() {
      let input = document.getElementById(this.id);

      input.focus();
    },
    createEventInput(input, browsers) {
      var that = this;
      // input.value = this.value;
      input.onfocus = function () {
        that.currentFocus = -1;
        browsers.style.display = "block";
        input.style.borderRadius = "5px 5px 0 0";
      };

      for (let option of browsers.options) {
        option.onclick = function () {
          input.value = option.text;
          browsers.style.display = "none";
          input.style.borderRadius = "5px";
          that.$emit("input", option.value);
          that.$emit("change");
        };
      }

      input.oninput = function () {
        that.currentFocus = -1;

        var text = input.value.toUpperCase();
        for (let option of browsers.options) {
          if (
            option.text.toUpperCase().indexOf(text) > -1 ||
            option.value.toUpperCase().indexOf(text) > -1
          ) {
            option.style.display = "block";
          } else {
            option.style.display = "none";
          }
        }
        that.$emit("input", input.value);
      };
      that.currentFocus = -1;
      input.onkeydown = function (e) {
        if (e.keyCode == 40) {
          that.currentFocus++;
          that.addActive(browsers.options);
        } else if (e.keyCode == 38) {
          that.currentFocus--;
          that.addActive(browsers.options);
        } else if (e.keyCode == 13) {
          e.preventDefault();
          if (that.currentFocus > -1) {
            /*and simulate a click on the "active" item:*/
            if (browsers.options) browsers.options[that.currentFocus].click();
          }
        }
      };
      document.getElementsByClassName("c-body")[0].onclick = function (e) {
        let role = e.target.getAttribute("role");

        if (
          role == "combobox" ||
          role == "listbox" ||
          role == "listbox" ||
          role == "img"
        )
          return;
        else {
          let datalist = document.querySelectorAll('[role="listbox"]');
          datalist.forEach(function (currentValue, currentIndex, listObj) {
            currentValue.style.display = "none";
          });
        }
      };
    },

    addActive(x) {
      if (!x) return false;
      this.removeActive(x);
      if (this.currentFocus >= x.length) this.currentFocus = 0;
      if (this.currentFocus < 0) this.currentFocus = x.length - 1;
      x[this.currentFocus].classList.add("active");
    },
    removeActive(x) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("active");
      }
    },
  },
};
</script>

<style lang="scss">
datalist {
  position: absolute;
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 100%;
  padding: 5px;
  max-height: 10rem;
  z-index: 2;
  overflow-y: auto;
}

datalist[role="listbox"] option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 5px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}

datalist[role="listbox"] option:hover,
datalist[role="listbox"] option.active {
  background-color: lightblue;
}
.arrow {
  position: absolute;
  color: #636f83;
  top: 59%;
  right: 10px;
  // transform: translate(0, -50%);
}
</style>
