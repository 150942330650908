import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    Permission: [],
  },
});

export const mutations = {
  setPermission: async function (state, val) {
    state.Permission = val;
  },
};

export const actions = {
  getPermission: async ({ commit }, payload) => {
    return axios(`${process.env.VUE_APP_API}/setting/getpermission`).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setPermission", data.data.detail);
          return data.data.detail;
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
