import axios from 'axios'
import VueCookies from 'vue-cookies';
import swal from 'sweetalert';


// const isHeaders = () => {
//   if (VueCookies.get('back_office_admin_token')) {
//     var setHeaders = {
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
//         'API-KEY': process.env.VUE_APP_API_KEY
//       }
//     }
//     return setHeaders
//   } else {
//     return null
//   }
// }
//  const IsSwalUnauthorized = (error) => {
//   if (error.statusText === 'Unauthorized') {
//     VueCookies.remove('back_office_admin_token');
//     swal(error.statusText, {
//       icon: "error",
//     }).then(function() {
//       window.location.href = "/";
//     });
//   }
//  }

export default {
  async GetVideoList (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Video/list`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetVideoByID (id) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/Video/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetVideoMainCategory () {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/video/category`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetVideoSubCategory (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/video/subcategory`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async CreateVideo (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/Video`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async UpdateVideo (form) {
    try {
      var response = await axios.put(`${process.env.VUE_APP_API}/Video/${form.id}`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async DeleteVideo (id) {
    try {
      var response = await axios.delete(`${process.env.VUE_APP_API}/Video/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
}
