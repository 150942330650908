const Customer = {
  path: "",
  name: "",
  redirect: "/report/customer",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/report/customer",
      redirect: "/report/customer",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Customer",
      meta: {
        requiresAuth: true,
        activeUrl: "/report/customer",
        parent: "/report/customer",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/report/customer"),
          meta: {
            requiresAuth: true,
            activeUrl: "/report/customer",
            parent: "/report/customer",

            lv: 2,
          },
        },
        {
          path: ":id",
          name: "Customer Detail",
          component: () =>
            import("@/views/pages/report/customer/detail/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/report/customer",
            parent: "/report/customer",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Customer;
