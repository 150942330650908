import axios from "axios";
export const state = () => ({
    state: {
        stateActivityLog: {},
        stateActivityType: {},
        stateExportActivityLog : {},
        stateDetailById : {}
    }
})

export const mutations = {
    setActivityLog: async function (state, val) {
        state.stateActivityLog = val;
    },
    setActivityType: async function (state, val) {
        state.stateActivityType = val;
    },
    setExportActivityLog: async function (state, val) {
        state.stateExportActivityLog = val;
    },
    setDetailById: async function (state, val) {
        state.stateDetailById = val;
    },
}

export const actions = {
    getActivityLog: async ({ commit }, payload) => {
        await axios.post(`${process.env.VUE_APP_API}/ActivityLog/list`, payload)
            .then(async data => {
                commit('setActivityLog', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    getListActivityType: async ({ commit }) => {
        await axios.get(`${process.env.VUE_APP_API}/ActivityLog/activity_type`)
            .then(async data => {
                commit('setActivityType', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
    exportActivityLog: async ({ commit }, payload) => {
        await axios.post(`${process.env.VUE_APP_API}/ActivityLog/export`, payload, {
            responseType: 'blob'
        })
            .then(async data => {
                commit('setExportActivityLog', data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {
                    // commit('setModalAlertLogin', true);
                }
            }
            );
    },
    getDetailById: async ({ commit }, payload) => {
        await axios.get(`${process.env.VUE_APP_API}/ActivityLog/${payload}`)
            .then(async data => {
                commit('setDetailById', data.data);
            }, (error) => {
                const code = parseInt(error.response && error.response.status);
                if (code === 401) {

                }
            }
            );
    },
}

export default {
    state,
    actions,
    mutations
}