import Vue from "vue";
import Vuex from "vuex";
import moduleConnectAPI from "./connectAPI/index";
import coupon from "./modules/coupon";
import moduleSalesChannel from "./connectAPISalesChannel/index";
import stampcard from "./modules/stampcard";
import modulePrivilegeCode from "./connectAPIPrivilegeCode/index";
import form from "./modules/form";
import extraPoint from "./modules/extraPoint";
import allRightRequest from "./modules/allrightrequest";
import permission from "./modules/permission";
import moduleVideo from "./connectAPIVideo/index";
import automation from "./modules/automation";
import importFile from "./modules/importFile";
import memberTier from "./modules/memberTier";
import theme from "./modules/theme";
import redeem from "./modules/redeem";
import report from "./modules/report";
import booking from "./modules/booking";
import product from "./modules/product";
import setting from "./modules/setting";
import activitylog from "./modules/activitylog";
import segment from "./modules/segment";
import friendgetfriend from "./modules/friendgetfriend";

import { state, actions, mutations, getters } from "./modules/syncFilter";

Vue.use(Vuex);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    formatDateNew: "DD/MM/YYYY",
    formatDate: "DD MMM YYYY",
    formatDateTime: "DD MMM YYYY HH:mm",
    formatTime: "HH:mm",
    screenSize: {
      width: 0,
      height: 0,
    },

    ...state,
  },
  getters: {
    ...getters,
  },
  mutations: {
    setScreenSize(state, screenSize) {
      state.screenSize.width = screenSize.width;
      state.screenSize.height = screenSize.height;
    },
    ...mutations,
  },
  actions: { clearFilter: async ({ commit, state }) => {}, ...actions },
  modules: {
    moduleConnectAPI,
    coupon,
    moduleSalesChannel,
    stampcard,
    modulePrivilegeCode,
    form,
    extraPoint,
    allRightRequest,
    moduleVideo,
    automation,
    importFile,
    memberTier,
    theme,
    redeem,
    report,
    booking,
    product,
    setting,
    activitylog,
    permission,
    segment,
    friendgetfriend,
    // syncFilter,
  },
});
