import axios from 'axios'
import VueCookies from 'vue-cookies';
import swal from 'sweetalert';


// const isHeaders = () => {
//   if (VueCookies.get('back_office_admin_token')) {
//     var setHeaders = {
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
//         'API-KEY': process.env.VUE_APP_API_KEY
//       }
//     }
//     return setHeaders
//   } else {
//     return null
//   }
// }
//  const IsSwalUnauthorized = (error) => {
//   if (error.statusText === 'Unauthorized') {
//     VueCookies.remove('back_office_admin_token');
//     swal(error.statusText, {
//       icon: "error",
//     }).then(function() {
//       window.location.href = "/";
//     });
//   }
//  }

export default {
  async GetSalesChannel (form) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/SellChannel?search=${form.search}&page=${form.page}&take=${form.take}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async GetSalesChannelByID (id) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/SellChannel/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async CreateSalesChannel (form) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}/SellChannel`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async UpdatetSalesChannel (form) {
    try {
      var response = await axios.put(`${process.env.VUE_APP_API}/SellChannel`, form)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
  async DeleteSalesChannel (id) {
    try {
      var response = await axios.delete(`${process.env.VUE_APP_API}/SellChannel/${id}`)
      return response.data
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response
    }
  },
}
