import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    modalAlertLogin: false,
    list: {},
    redeemDetail: {},
    redeemOrderList: [],
    productList: [],
    productSelectList: [],
    branchList: [],
    updateStatusRedeem: [],
    exportRedeem: [],
    createOrUpdate: [],
    stateNoteRedeem: null,
    stateUpdateNoteRedeem: null,
    stateImportReportRedeem: null,
  },
});

export const mutations = {
  setList: async function (state, val) {
    state.list = val;
  },
  setRedeemDetail: async function (state, val) {
    state.redeemDetail = val;
  },
  setRedeemOrderList: async function (state, val) {
    state.redeemOrderList = val;
  },
  setProductList: async function (state, val) {
    state.productList = val;
  },
  setProductSelectList: async function (state, val) {
    state.productSelectList = val;
  },
  setBranchList: async function (state, val) {
    state.branchList = val;
  },
  setUpdateStatusRedeem: async function (state, val) {
    state.updateStatusRedeem = val;
  },
  setExportRedeem: async function (state, val) {
    state.exportRedeem = val;
  },
  setCreateOrUpdateRedeem: async function (state, val) {
    state.createOrUpdate = val;
  },
  setGetNoteRedeem: async function (state, val) {
    state.stateNoteRedeem = val;
  },
  setUpdateNoteRedeem: async function (state, val) {
    state.stateUpdateNoteRedeem = val;
  },
  setImportReportRedeem: async function (state, val) {
    state.stateImportReportRedeem = val;
  },
};

export const actions = {
  getListRedeem: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/redeem`, payload).then(
      async (data) => {
        commit("setList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getRedeemDetail: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/redeem/statusdetail`, payload)
      .then(
        async (data) => {
          commit("setRedeemDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getRedeemOrderList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/redeem/GetRedeemOrderList`, payload)
      .then(
        async (data) => {
          commit("setRedeemOrderList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getListProduct: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/redeem/GetRedeemProductListByCategory`,
        payload
      )
      .then(
        async (data) => {
          commit("setProductList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getListProductSelect: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/redeem/GetRedeemProductList`, payload)
      .then(
        async (data) => {
          commit("setProductSelectList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getBranchList: async ({ commit }, payload) => {
    await axios.get(`${process.env.VUE_APP_API}/redeem/GetBranchList`).then(
      async (data) => {
        commit("setBranchList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateStatusRedeem: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/redeem/UpdateStatusRedeemOrder`,
        payload
      )
      .then(
        async (data) => {
          commit("setUpdateStatusRedeem", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  exportByRedeemOrderId: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/redeem/ExportByRedeemOrderIdList`,
        payload,
        {
          responseType: "blob",
        }
      )
      .then(
        async (data) => {
          commit("setExportRedeem", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createOrUpdateRedeem: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/redeem/CreateOrUpdateRedeem`, payload)
      .then(
        async (data) => {
          console.log(data.response);
          commit("setCreateOrUpdateRedeem", data.data);
        },
        (error) => {
          console.log(error);
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          } else {
            return data.data;
          }
        }
      );
  },
  getNoteRedeem: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Redeem/RedeemOrderNote/${payload}`)
      .then(
        async (data) => {
          commit("setGetNoteRedeem", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateNoteRedeem: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Redeem/UpdateRedeemOrderNote`, payload)
      .then(
        async (data) => {
          commit("setUpdateNoteRedeem", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  importReportRedeem: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Redeem/import_redeem_order`, payload)
      .then(
        async (data) => {
          commit("setImportReportRedeem", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
