import axios from "axios";
import VueCookies from "vue-cookies";
import swal from "sweetalert";

export default {
  // news
  async Login(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Login/login`,
        form
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async LoginAzure(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Login/loginazure`,
        form
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async GetLoginAzureSetting() {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Setting/get_login_azure_setting`
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async GetNews(value) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/News?take=${value.take}&page=${value.page}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetNewByID(id) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/News/${id}`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateNews(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/News/CreateNews`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdatetNews(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/News/UpdatetNews`,
        form
      );

      return response.data;
    } catch (error) {
      console.log(error);
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async DeleteNews(id) {
    try {
      var response = await axios.delete(
        `${process.env.VUE_APP_API}/News/DeleteNews/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetNewsType() {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/News/GetType`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetNotificationNews(id) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Notification/GetNews/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UploadImage(form) {
    try {
      function detectMimeType(b64) {
        return b64.indexOf("image/png") != -1 ? 1 : 0;
      }
      console.log(detectMimeType(form.Base64String));
      let payload = {
        type: detectMimeType(form.Base64String),
        ...form,
      };
      var response = await axios.post(
        `${process.env.VUE_APP_API}/News/UploadImage`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UploadImageSize(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/News/UploadImageReturnSize`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  // Notification
  async GetNotificationPerPage(value) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Notification?take=${value.take}&page=${value.page}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetNotificationByID(id) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Notification/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async PushNotification(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Notification/push_notification`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CancelNotification(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Notification/cancle_notification`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  //banner
  async GetBanner(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Banner`,
        form
      );
      return response.data;
    } catch (error) {
      await IsSwalUnauthorized(error.response);
      return error.response;
    }
  },
  async GetBannerByID(id) {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/Banner/${id}`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateBanner(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Banner/CreateBanner`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdateBanner(form) {
    try {
      var response = await axios.put(
        `${process.env.VUE_APP_API}/Banner/UpdateBanner`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async DeleteBanner(id) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Banner/DeleteBanner/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetRedirectType() {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Banner/GetRedirectType`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetRedirect(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Banner/GetRedirect`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  // product
  async SearchProduct(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Product/search`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetProductByID(id) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/product/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async DeleteProduct(id) {
    try {
      var response = await axios.delete(
        `${process.env.VUE_APP_API}/product/DeleteProduct/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateProduct(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Product/CreateProduct`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdatetProduct(form) {
    try {
      var response = await axios.put(
        `${process.env.VUE_APP_API}/Product/UpdatetProduct`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetSubCategory(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Product/GetSubCategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetRelateCategory(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Product/GetRelateCategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  // Sell Channel
  async SellChannel() {
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}/SellChannel`);
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  // category
  async GetAllCategory(form) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Category/GetAllCategory?search=${form.search}&include_subcategory=${form.includeSubcategory}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetCategoryByID(id) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Category/${id}`
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async Subcategory(form) {
    try {
      var response = await axios.get(
        `${process.env.VUE_APP_API}/Category/Subcategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateMainCategory(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Category`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async CreateSubCategory(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Category/CreateSubcategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdateMainCategory(form) {
    try {
      var response = await axios.put(
        `${process.env.VUE_APP_API}/Category`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async UpdateSubCategory(form) {
    try {
      var response = await axios.put(
        `${process.env.VUE_APP_API}/Category/UpdateSubcategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
  async GetProductCategory(form) {
    try {
      var response = await axios.post(
        `${process.env.VUE_APP_API}/Category/Subcategory`,
        form
      );
      return response.data;
    } catch (error) {
      // await IsSwalUnauthorized(error.response)
      return error.response;
    }
  },
};
