<template>
  <div :class="['select-custom', { error: isValidate }]">
    <label v-if="title" class="">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <br v-if="title" />

    <b-dropdown
      :id="`dropdown-${name}`"
      block
      variant="dropdown-checkbox"
      menu-class="w-100"
      no-caret
    >
      <template #button-content>
        <div class="content-between" style="column-gap: 5px">
          <p class="text-left mb-0">{{ valueOfInput }}</p>
          <font-awesome-icon icon="chevron-down" />
        </div>
      </template>
      <div class="px-3">
        <b-form-group>
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              :id="`checkbox-${name}`"
              :checked="[...value]"
              @change="onDataChange"
              :options="options"
              :aria-describedby="ariaDescribedby"
              :name="`checkbox${name}`"
              :value-field="valueField"
              :text-field="textField"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
      </div>
    </b-dropdown>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">Please select.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    value: {
      required: false,
      type: Number | String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    valueField: {
      required: false,
      type: String,
    },
    textField: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    noPleaseSelect: {
      required: false,
      type: Boolean,
    },
    indexCondition: {
      required: false,
      type: Number,
    },
    indexGroup: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  computed: {
    valueOfInput() {
      if (this.options.filter((el) => this.value.includes(el.id)).length == 0) {
        return "--- Please Select Value ---";
      } else {
        return this.options
          .filter((el) => this.value.includes(el.id))
          .map((el) => el.name)
          .join(" , ");
      }
    },
  },
  methods: {
    onDataChange(event) {
      let value = this.options.find((option) => option.id === event);

      if (value) {
        this.$emit(
          "onDataChange",
          event,
          this.indexCondition,
          value.name,
          this.indexGroup
        );
      } else {
        this.$emit(
          "onDataChange",
          event,
          this.indexCondition,
          "",
          this.indexGroup
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-custom.error > .btn-select {
  border-color: red !important;
}
::v-deep .dropdown {
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  background: white;
  color: #768192;
  min-height: 37px;
  ul {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
