<template>
  <div>
    <div>
      <InputSelect
        :title="customText"
        name="date"
        class="mt-2"
        v-model="filter.dateType"
        :options="dateType"
        valueField="value"
        textField="text"
        @input="$emit('input', $event)"
      >
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select Date Filter --</b-form-select-option
          >
        </template>
      </InputSelect>
    </div>
    <div v-if="filter.dateType == 0">
      <InputDatePickerFilter
        :textFloat="`${customText} From`"
        name="date-from"
        ref="date-from"
        :value="filter.form"
        :maxDatetime="range.max"
        :minDatetime="range.min"
        @input="(val) => (filter.form = val)"
      />
    </div>

    <div v-if="filter.dateType == 0">
      <InputDatePickerFilter
        :textFloat="`${customText} To`"
        name="date-to"
        ref="date-to"
        :maxDatetime="range.max"
        :minDatetime="filter.form || range.min"
        :value="filter.to"
        @input="(val) => (filter.to = val)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      required: true,
    },
    customText: { required: false, default: "Date" },
  },
  data() {
    return {
      dateType: [
        { text: "This week", value: 1 },
        { text: "This month", value: 2 },
        { text: "This Year", value: 3 },
        { text: "Custom", value: 0 },
      ],
      range: [],
      valueDateType: null,
    };
  },
  computed: {
    ...mapGetters(["filter"]),
  },

  created() {
    this.getRangeTransaction();
  },
  methods: {
    async getRangeTransaction() {
      const date = await this.$store.dispatch("getFilterDate", this.id);

      this.range = date.map((el) => {
        return { max: el.max_date, min: el.min_date };
      })[0];
    },
  },
};
</script>

<style></style>
